import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1f96a780 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _52d9b52c = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _3ad90f36 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _526242f0 = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _659019c1 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _4d1b944e = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _6f08b88a = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _20406d6f = () => interopDefault(import('../pages/Installment/index.vue' /* webpackChunkName: "pages/Installment/index" */))
const _0f637bfb = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _07e1cb0e = () => interopDefault(import('../pages/Reviews.vue' /* webpackChunkName: "pages/Reviews" */))
const _f5ba68b4 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _4bc3174b = () => interopDefault(import('../pages/Trade-in.vue' /* webpackChunkName: "pages/Trade-in" */))
const _3b6776b6 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _cf0b31b6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _692b1c33 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _0d7fbed0 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _f44244c2 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _5eb5044a = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _1f96a780,
    name: "About"
  }, {
    path: "/Banks",
    component: _52d9b52c,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _3ad90f36,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _526242f0,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _659019c1,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _4d1b944e,
    name: "Credit"
  }, {
    path: "/Favorites",
    component: _6f08b88a,
    name: "Favorites"
  }, {
    path: "/Installment",
    component: _20406d6f,
    name: "Installment"
  }, {
    path: "/Privacy",
    component: _0f637bfb,
    name: "Privacy"
  }, {
    path: "/Reviews",
    component: _07e1cb0e,
    name: "Reviews"
  }, {
    path: "/Thanks",
    component: _f5ba68b4,
    name: "Thanks"
  }, {
    path: "/Trade-in",
    component: _4bc3174b,
    name: "Trade-in"
  }, {
    path: "/Credit/:bank",
    component: _3b6776b6,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _cf0b31b6,
    name: "index"
  }, {
    path: "/:category",
    component: _692b1c33,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _0d7fbed0,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _f44244c2,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _5eb5044a,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
